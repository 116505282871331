import React from 'react'
import { Link, navigate } from 'gatsby'
import { Layout } from "../layout/layout"
import { SearchProvider } from '../../context/search-provider'
import { StoreContext } from "../../context/store-context"
import { useMutation } from 'urql'
import Alert from '../utils/Alert'

const ResetPassword = `
mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
        customer {
            firstName
        }
        customerAccessToken {
            accessToken
            expiresAt
        }
        customerUserErrors {
            code
            field
            message
        }
    }
}
`

function PasswordPage({ id, token }) {
    const [loading, setLoading] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [alert, setAlert] = React.useState(undefined)
    
    const [resetPasswordResult, resetPassword] = useMutation(ResetPassword)

    const { storeToken } = React.useContext(StoreContext)

    const reset = async (e) => {
        e.preventDefault()
        setAlert(undefined)
        try {
            if (password === '' || confirmPassword === '') return

            if (password !== confirmPassword) {
                setAlert({
                    type: 'error',
                    message: 'The password confirmation must match the provided password.',
                    link: '',
                    linkText: ''
                })
                
                return
            }

            setLoading(true)
            const customerResetInput = {
                password: password,
                resetToken: token
            }
            const variables = { id: `gid://shopify/Customer/${id}`, input: customerResetInput }
            const { data: { customerReset }, error } = await resetPassword(variables)

            console.log('[error]', error)

            if (error) {
                throw new Error(error.message)
            }
            else if (customerReset.customerUserErrors.length) {
                const [firstError] = customerReset.customerUserErrors
                throw new Error(firstError.message)
            }
            else {
                setLoading(false)
                setAlert({
                    type: 'success',
                    message: 'Password reset success, redirecting you to your account page...',
                    link: '',
                    linkText: ''
                })

                setTimeout(() => {
                    storeToken(customerReset?.customerAccessToken)
                    navigate('/account')
                }, 2000)
            }
        } catch (error) {
            setLoading(false)
            setAlert({
                type: 'error',
                message: error.message,
                link: '',
                linkText: ''
            })
            console.error(error)
        }
    }

    return (
        <Layout>
            <div className="w-full mx-auto" id="page-wrapper">

                <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                    <div className="text-4xl font-bold w-full text-center">
                        <h1 className="leading-tight font-normal text-45xl">Reset your password</h1>
                    </div>
                </div>

                { alert && <div className='pt-4 px-4'><Alert type={alert.type} msg={alert.message} link={alert.link} linkText={alert.linkText} /></div> }

                <div className="form-body flex flex-col mb-4 w-full mx-auto py-4 px-4 max-w-2xl relative">
                    {
                        loading && (
                            <div className="absolute bg-background-overlay left-0 top-0 w-full h-full flex items-center justify-center z-50">
                                <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                            </div>
                        )
                    }

                    <form action="" method="post" onSubmit={e => reset(e)}>
                        <div className="form-group mb-4">
                            <label htmlFor="password" className="block mb-1 uppercase">Password</label>
                            <input
                                type="password" 
                                name="password" 
                                id="password" 
                                className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal"
                                required
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="password" className="block mb-1 uppercase">Confirm Password</label>
                            <input
                                type="password" 
                                name="confirm_password" 
                                id="confirm_password" 
                                className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal"
                                required
                                onChange={e => setConfirmPassword(e.target.value)}
                            />
                        </div>

                        <div className="button-group mt-4">
                            <button
                                type="submit"
                                className="button w-full sm:w-auto items-center justify-center bg-primary border border-primary text-white py-4 px-8 uppercase text-sm tracking-widest font-bold mb-4">
                                <span>Reset Password</span>
                            </button>
                            <Link to="/account/login/" className="block text-primary text-center sm:text-left font-normal underline">Cancel</Link>
                        </div>
                    </form>
                </div>

            </div>
        </Layout>
    )
}

export default function PasswordPageTemplate(props) {
    return (
        <SearchProvider>
            <PasswordPage {...props} />
        </SearchProvider>
    )
}