import React from "react"
import { Router } from "@reach/router"
import Reset from "../../components/account/password"

const App = () => {
    return (
        <Router basepath="/app">
            <Reset path="/password/:id/:token" />
        </Router>
    )
}

export default App